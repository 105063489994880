import React, { useState, useEffect } from "react";
import NotFound from "../NotFound/NotFound";
import Box from "../Box/Box";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import { FaSignal, FaTiktok, FaWhatsapp } from "react-icons/fa";
import { BiTimeFive } from "react-icons/bi";
import { LiaLanguageSolid } from "react-icons/lia";
import { GiOpenBook } from "react-icons/gi";
import { LuCalendarDays } from "react-icons/lu";
import { AiOutlineClose, AiFillYoutube, AiFillFacebook } from "react-icons/ai";
import { IoMdAlbums } from "react-icons/io";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import "./course.css";
import "swiper/css";
import "swiper/css/navigation";
import {
  get_image,
  get_slider,
  parse_course_URL,
  get_course_URL,
} from "../../helper";
import api from "../../axios";
const Course = () => {
  /*Gradutes' Gallery*/
  const [course, setCourse] = useState({});
  const [covers, setCovers] = useState([]);
  const [cards, setCards] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [popupImages, setPopupImages] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [isSliderPopupOpen, setIsSliderPopupOpen] = useState(false);
  const [setting, setSetting] = useState({});
  const [courses, setCourses] = useState([]);
  const { id } = useParams();
  const courseID = parse_course_URL(id);
  useEffect(() => {
    api.get("/courses").then((response) => {
      setCourses(response.data.data);
    });
    api
      .get("courses/" + courseID, {
        params: {
          pagination: {
            pageSize: 50,
          },
          populate: [
            "instructors.image",
            "gallery.image",
            "gallery.images",
            "home_image",
            "sliders",
            "video",
            "pdf_file",
          ],
          locale: "en",
        },
      })
      .then((response) => {
        setCourse(response.data.data);
        setCovers(response?.data?.data?.attributes?.sliders?.data || []);
        setInstructors(response?.data?.data?.attributes?.instructors || []);
        setCards(response?.data?.data?.attributes?.gallery || []);
        console.log(response?.data?.data?.attributes);
      })
      .catch((error) => {
        setNotFound(true);
        console.log(error);
      });
    api
      .get("/setting", {
        params: {
          populate: "*",
        },
      })
      .then((response) => {
        setSetting(response.data.data.attributes);
      });
  }, [courseID]);
  if (notFound === true) return <NotFound />;
  if (!course?.id) return "Loading..";
  const handleImageClick = (images, index) => {
    setPopupImages(images?.data || []);
    setSliderIndex(index);
    setIsSliderPopupOpen(true);
  };
  const formatDescription = (text) => {
    return text.replace(/\n/g, "<br />");
  };
  // Function to check if the text is Arabic
  const isArabic = (text) => {
    const arabicPattern = /[\u0600-\u06FF]/;
    return arabicPattern.test(text);
  };

  // Determine the direction based on the description language
  const description = course?.attributes?.description || "";
  //const instructorDescription = course?.attributes?.instructors?.description || "";
  const direction = isArabic(description) ? "rtl" : "ltr";
  //const instructorDirection = isArabic(instructorDescription) ? "rtl" : "ltr";
  const phoneNumber = "+201027097226";
  const whatsappURL = `https://wa.me/${phoneNumber}`;
  return (
    <>
      <section className="interior">
        <div className="intro-image">
          <Swiper
            navigation={true}
            modules={[Navigation, Autoplay, Pagination]}
            className="mySwiper"
            autoplay={{ delay: 2000 }}
            pagination={{ clickable: true }}
          >
            {covers.map((cover, index) => (
              <SwiperSlide key={index}>
                <div className="intro-image-container">
                  <img
                    className="animated-coverflow"
                    loading="lazy"
                    src={get_slider(cover)}
                    alt="cover"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="section-container">
          <div className="text">
            <h1>{course?.attributes?.title}</h1>
            <p>
              A course by <strong>{course?.attributes?.author}</strong>
            </p>
          </div>
          <div className="content">
            <div className="left">
              <div className="media">
                <div className="video">
                  <video
                    src={get_image(course?.attributes?.video)}
                    controls={true}
                  />
                </div>
                <div className="mobile-info">
                  <h3>About the course:</h3>
                  <p>
                    <div className="icons">
                      <FaSignal size={20} className="icons" />
                    </div>
                    Level: {course?.attributes?.level}
                  </p>
                  <p>
                    <div className="icons">
                      <BiTimeFive size={20} className="icons" />
                    </div>
                    Duration: {course?.attributes?.duration}
                  </p>
                  <p>
                    <div className="icons">
                      <LiaLanguageSolid size={21} className="icons" />
                    </div>
                    Language: {course?.attributes?.language}
                  </p>
                  <p>
                    <div className="icons">
                      <LuCalendarDays size={20} className="icons" />
                    </div>
                    Starting Date: {course?.attributes?.starting_date}
                  </p>
                  <p>
                    <div className="icons">
                      <GiOpenBook size={20} className="icons" />
                    </div>
                    Topics: {course?.attributes?.topics}
                  </p>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="book">
                <Box
                  textContent={{
                    discount: course?.attributes?.discount || "",
                  }}
                  buttonHref={course?.attributes?.form_url || "#"}
                  whatsapp_link={setting?.whatsapp_link}
                  confirmationMessage={course?.attributes?.confirmation_message}
                />
                <div className="info">
                  <h3>About the course:</h3>
                  <p>
                    <div className="icons">
                      <FaSignal className="icons" />
                    </div>
                    Level: {course?.attributes?.level}
                  </p>
                  <p>
                    <div className="icons">
                      <BiTimeFive className="icons" />
                    </div>
                    Duration: {course?.attributes?.duration}
                  </p>
                  <p>
                    <div className="icons">
                      <LiaLanguageSolid className="icons" />
                    </div>
                    Language: {course?.attributes?.language}
                  </p>
                  <p>
                    <div className="icons">
                      <LuCalendarDays className="icons" />
                    </div>
                    Starting Date: {course?.attributes?.starting_date}
                  </p>
                  <p>
                    <div className="icons">
                      <GiOpenBook className="icons" />
                    </div>
                    Topics: {course?.attributes?.topics}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {instructors.length > 0 && (
            <div className="instructors" id="urban-mobile">
              <h2>Course Instructors</h2>
              {instructors.map((instructor) => (
                <div key={instructor?.name} className="instructor-card">
                  <div className="image">
                    <img
                      src={get_image(instructor.image)}
                      alt={instructor?.name}
                    />
                  </div>
                  <div className="instructor-info">
                    <h4>
                      {instructor?.title} {instructor?.name}
                    </h4>
                    <p 
                    dangerouslySetInnerHTML={{
                    __html: formatDescription(instructor?.description),
                  }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          {description.length > 0 && (
            <div>
              <h2>Description</h2>
              <div id="desc">
                <p
                  style={{ direction }}
                  dangerouslySetInnerHTML={{
                    __html: formatDescription(course?.attributes?.description),
                  }}
                />
              </div>
            </div>
          )}
          {cards.length > 0 && (
            <div className="Gallery">
              <div className="text">
                <h2>Gallery</h2>
              </div>
              <div className="gallery">
                <div className="gallery-container">
                  {cards.map((card, index) => (
                    <div
                      className="gallery-card"
                      key={index}
                      onClick={() => handleImageClick(card.images, index)}
                    >
                      <div className="image">
                        <img src={get_image(card.image)} alt="gallery" />
                        <div>
                          <IoMdAlbums className="album" />
                        </div>
                      </div>
                      <div className="card-text">
                        <div className="heading">
                          <h1>{card.name}</h1>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {isSliderPopupOpen && (
                  <div className="slider-popup">
                    <div className="slider-popup-content">
                      <div
                        className="slider-close-icon"
                        onClick={() => setIsSliderPopupOpen(false)}
                      >
                        <AiOutlineClose />
                      </div>
                      <Swiper
                        navigation={true}
                        modules={[Navigation, Pagination]}
                        initialSlide={sliderIndex}
                        className="slider-popup-swiper"
                        pagination={{ clickable: true }}
                      >
                        {popupImages.map((image, index) => (
                          <SwiperSlide key={index}>
                            <div className="slider-popup-image">
                              <img src={get_slider(image)} alt="popup" />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="footer">
            <div className="social">
              <h3>Contact Us</h3>
              <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
                <FaWhatsapp /> {phoneNumber}
              </a>
              <a href={setting?.facebook_link} target="_blank" rel="noreferrer">
                <AiFillFacebook className="icon" /> Facebook
              </a>
              <a
                href={setting?.instagram_link}
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="icon" /> Instagram
              </a>
              <a
                href="mailto:info@sae-grad.com"
                target="_blank"
                rel="noreferrer"
              >
                <CiMail className="icon" /> Mail
              </a>
              <a href={setting?.tiktok_link} target="_blank" rel="noreferrer">
                <FaTiktok className="icon" /> TikTok
              </a>
              <a href={setting?.linkedin_link} target="_blank" rel="noreferrer">
                <BsLinkedin className="icon" /> LinkedIn
              </a>
              <a href={setting?.youtube_link} target="_blank" rel="noreferrer">
                <AiFillYoutube className="icon" /> Youtube
              </a>
            </div>
            <div className="course">
              <h3>Other Programs</h3>
              {courses.map((course) => (
                <Link to={"/courses/" + get_course_URL(course)}>
                  {course?.attributes?.title}
                </Link>
              ))}
            </div>
            <div className="book">
              <Box
                textContent={{
                  discount: course?.attributes?.discount || "",
                }}
                buttonHref={course?.attributes?.form_url || "#"}
                whatsapp_link={setting?.whatsapp_link}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Course;
