import React, { useState, useEffect } from "react";
import api from "../../axios";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RiCloseLine } from "react-icons/ri";
import { BiSolidCart } from "react-icons/bi";
import { AiOutlineMenu } from "react-icons/ai";
import "./navbar.css";
import logo from "../../assets/LOGO BLACK BG.PNG";
import Register from "../MobileRegister/Register";
import { get_course_URL } from "../../helper";
const Navbar = () => {
  const [courses, setCourses] = useState([]);
  const [services, setServices] = useState([]);
  const [books, setBooks] = useState([]);
  const [events, setEvents] = useState([]);
  const [collab, setCollab] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    api.get("/courses").then((response) => {
      setCourses(response.data.data);
    });
    api.get("/events").then((response) => {
      setEvents(response.data.data);
    });
    api.get("/services").then((response) => {
      setServices(response.data.data);
    });
    api.get("/play-books").then((response) => {
      setBooks(response.data.data);
    });
    api.get("/collaborators").then((response) => {
      setCollab(response.data.data);
    });
  }, []);
  return (
    <div className="navbar">
      <div className="mobile-navbar">
        <div className="logo">
          <Link to={"/"}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="menu-icon" onClick={handleMenuClick}>
          <AiOutlineMenu size={25} className="menuIcon" />
        </div>
        {isMenuOpen && (
          <div className="menu-content scale-up-center">
            <div className="close-icon" onClick={handleMenuClick}>
              <RiCloseLine size={25} />
            </div>
            <div className="menu-container">
              <Register />
              <div className="separator" />
              <Accordion>
                <AccordionSummary
                  id="panel1a-header"
                  aria-controls="panel1a-content"
                >
                  <Typography id="heading">
                    Events
                    <ExpandMoreIcon />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginTop: "-10px",
                  }}
                >
                  {events.map((event) => (
                    <Link
                      onClick={handleMenuClick}
                      style={{ marginBottom: "10px" }}
                      to={"/events/" + get_course_URL(event)}
                    >
                      {event?.attributes?.title}
                    </Link>
                  ))}
                </AccordionDetails>
              </Accordion>
              <div className="separator" />
              <Accordion>
                <AccordionSummary
                  id="panel1a-header"
                  aria-controls="panel1a-content"
                >
                  <Typography id="heading">
                    Programs
                    <ExpandMoreIcon />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginTop: "-10px",
                  }}
                >
                  {courses.map((course) => (
                    <Link
                      onClick={handleMenuClick}
                      style={{ marginBottom: "10px" }}
                      to={"/courses/" + get_course_URL(course)}
                    >
                      {course?.attributes?.title}
                    </Link>
                  ))}
                </AccordionDetails>
              </Accordion>
              <div className="separator" />
              <Accordion>
                <AccordionSummary
                  id="panel1a-header"
                  aria-controls="panel1a-content"
                >
                  <Typography id="heading">
                    Playbooks
                    <ExpandMoreIcon />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginTop: "-10px",
                  }}
                >
                  {books.map((book) => (
                    <Link
                      onClick={handleMenuClick}
                      style={{ marginBottom: "10px" }}
                      to={"/play-books/" + get_course_URL(book)}
                    >
                      {book?.attributes?.title}
                    </Link>
                  ))}
                </AccordionDetails>
              </Accordion>
              <div className="separator" />
              <Accordion>
                <AccordionSummary
                  id="panel1a-header"
                  aria-controls="panel1a-content"
                >
                  <Typography id="heading">
                    Services
                    <ExpandMoreIcon />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginTop: "-10px",
                  }}
                >
                  {services.map((service) => (
                    <Link
                      onClick={handleMenuClick}
                      style={{ marginBottom: "10px" }}
                      to={"/services/" + get_course_URL(service)}
                    >
                      {service?.attributes?.title}
                    </Link>
                  ))}
                </AccordionDetails>
              </Accordion>
              <div className="separator" />
              <Accordion>
                <AccordionSummary
                  id="panel1a-header"
                  aria-controls="panel1a-content"
                >
                  <Typography id="heading">
                    Collaborators
                    <ExpandMoreIcon />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    marginTop: "-10px",
                  }}
                >
                  {collab.map((colab) => (
                    <Link
                      onClick={handleMenuClick}
                      style={{ marginBottom: "10px" }}
                      to={"/collaborators/" + get_course_URL(colab)}
                    >
                      {colab?.attributes?.title}
                    </Link>
                  ))}
                </AccordionDetails>
              </Accordion>
              <div className="separator" />
              <div className="menu-icons">
                <BiSolidCart size={50} />
                <p>ع</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
