import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import api from "../../axios";
import "./box.css";
import { BsWhatsapp } from "react-icons/bs";

const Box = ({ textContent, whatsapp_link = "https://wa.me/201027097226", confirmationMessage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const { id } = useParams();
  const location = useLocation();

  const determineSourceType = () => {
    if (location.pathname.includes("/courses")) return "courses";
    if (location.pathname.includes("/services")) return "services";
    if (location.pathname.includes("/play-books")) return "playbooks";
    if (location.pathname.includes("/events")) return "events";
    return "unknown";
  };

  const sourceType = determineSourceType();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    phone: "",
    university: "",
    graduation_year: "",
    course: "",
    registered_in: location.pathname,
  });

  useEffect(() => {
    if (sourceType !== "unknown") {
      const endpoint = `/${sourceType}/${id}`;
    //  console.log(`Fetching data for ${sourceType} with id: ${id} from endpoint: ${endpoint}`);
      api.get(endpoint)
        .then(response => {
          const sourceData = response.data.data;
         // console.log(`${sourceType} data fetched:`, sourceData);
          setFormData(prevState => ({
            ...prevState,
            course: sourceData.title || sourceData.name || "N/A",
          }));
        })
        .catch(error => {
          console.error(`Error fetching ${sourceType} details from ${endpoint}:`, error);
        });
    } else {
      console.warn(`Unknown source type for path: ${location.pathname}`);
    }
  }, [id, sourceType, location.pathname]);

  const handleButtonClick = () => {
    setIsOpen(true);
    setIsPaymentOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsPaymentOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    api.post("/contacts", { data: formData })
      .then((res) => {
        //console.log("Response:", res.data);
        alert(confirmationMessage || "You've booked your offer, We will contact you as soon as possible!"); 
        setFormData({
          name: "",
          email: "",
          country: "",
          phone: "",
          university: "",
          graduation_year: "",
          course: "",
          registered_in: location.pathname,
        });
      })
      .catch((e) => {
        console.error("Error:", e);
        alert("Invalid data");
      });
  };

  return (
    <div className="book">
      <div className="box">
        <div className="text">
          <p>{textContent.discount}</p>
        </div>
        <div className="payment">
          <div className="button">
            <button onClick={handleButtonClick}>
              {sourceType === "events" ? "Join Now" : "Get Offer"}
            </button>
            <button id="chat">
              <a
                href={whatsapp_link}
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp"
              >
                Chat
                <BsWhatsapp size={15} />
              </a>
            </button>
            {isOpen && !isPaymentOpen && (
              <div className="paymentpopup">
                <div className="Popup-content">
                  <AiOutlineClose
                    className="close-icon"
                    onClick={handleClose}
                    size={35}
                    color="white"
                  />
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <label htmlFor="name">
                        Name:
                        <input
                          type="text"
                          name="name"
                          placeholder="Your Name..."
                          value={formData.name}
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                          required
                        />
                      </label>
                      <label htmlFor="email">
                        E-mail:
                        <input
                          type="email"
                          name="email"
                          placeholder="Your E-mail..."
                          value={formData.email}
                          onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                          }
                          required
                        />
                      </label>
                    </div>
                    <label>
                      Country:
                      <input
                        type="text"
                        placeholder="Enter Your Country"
                        name="country"
                        value={formData.country}
                        onChange={(e) =>
                          setFormData({ ...formData, country: e.target.value })
                        }
                        required
                      />
                      <label>
                        WhatsApp Number:
                        <input
                          type="text"
                          name="phoneNumber"
                          value={formData.phone}
                          onChange={(e) =>
                            setFormData({ ...formData, phone: e.target.value })
                          }
                          placeholder="Enter your phone number"
                          required
                        />
                      </label>
                    </label>
                    <div className="row">
                      <label htmlFor="university">
                        University:
                        <input
                          type="text"
                          name="university"
                          placeholder="Your University..."
                          value={formData.university}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              university: e.target.value,
                            })
                          }
                          required
                        />
                      </label>
                      <label htmlFor="grad-year">
                        Graduation Year:
                        <input
                          type="text"
                          name="graduationYear"
                          placeholder="Graduation Year..."
                          value={formData.graduation_year}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              graduation_year: e.target.value,
                            })
                          }
                          required
                        />
                      </label>
                    </div>
                    <div className="next">
                      <button type="submit">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Box;
