import axios from "axios";

const api = axios.create({
  baseURL: "https://api.sae-grad.com/api",
});

api.interceptors.request.use(
  (config) => {
    const token =
      "b64e49efc75f3e8fe6bd65fbd5536572230164c9ae29797fbbe4d21c2a5e721740d092a89c2ffa7a9e51e23e9db4674f5869ed4e5d7570afec99a2543a8eccbd4d433c440f4442e589ae0246941b26926a737a98b806425bcc0c4ec1bb0169c5a96155ff33f9368b09351b700970078991057c6b641ea05c6e9397849b87d6ce";
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
